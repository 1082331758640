import React from "react";
import { CheckCircle, Circle } from "lucide-react";
import { useTranslation } from "react-i18next";

export function DocumentSteps({ currentStep, onStepClick }) {
  const { t } = useTranslation();
  const steps = [
    {
      title: t("documentDetails.emailAccountSelection"),
      description: t("documentDetails.selectEmailAccount"),
    },
    {
      title: t("documentDetails.headerSettings"),
      description: t("documentDetails.setHeaderOrder"),
    },
    {
      title: t("documentDetails.emailTemplateCreation"),
      description: t("documentDetails.createOrSelectTemplate"),
    },
    {
      title: t("documentDetails.emailPreview"),
      description: t("documentDetails.previewFirst3Rows"),
    },
  ];

  return (
    <div className="mb-8">
      <ol className="flex items-center w-full space-x-2 sm:space-x-4">
        {steps.map((step, index) => (
          <li
            key={index}
            className={`flex flex-col items-center w-full ${
              index < steps.length - 1 ? "relative" : ""
            }`}
          >
            <button
              onClick={() => onStepClick(index)}
              className={`flex flex-col items-center w-full p-2 rounded-lg transition-all duration-200 ${
                index <= currentStep
                  ? "bg-blue-50 hover:bg-blue-100"
                  : "hover:bg-gray-100"
              } focus:outline-none focus:ring-2 focus:ring-blue-300`}
              disabled={index > currentStep}
            >
              <div className="flex items-center justify-center mb-2">
                {index < currentStep ? (
                  <CheckCircle className="w-8 h-8 text-blue-600" />
                ) : index === currentStep ? (
                  <Circle className="w-8 h-8 text-blue-600" />
                ) : (
                  <Circle className="w-8 h-8 text-gray-300" />
                )}
              </div>
              <div className="text-center">
                <h3
                  className={`text-sm font-medium ${
                    index <= currentStep ? "text-blue-600" : "text-gray-500"
                  }`}
                >
                  {step.title}
                </h3>
                <p className="mt-1 text-xs text-gray-400 hidden sm:block">
                  {step.description}
                </p>
              </div>
            </button>
            {index < steps.length - 1 && (
              <div className="absolute top-4 w-full h-0.5 bg-gray-200 -z-10">
                <div
                  className="h-full bg-blue-600 transition-all duration-300 ease-in-out"
                  style={{ width: index < currentStep ? "100%" : "0%" }}
                ></div>
              </div>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
}
