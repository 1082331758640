import React from "react";
import { useQuery } from "@tanstack/react-query";
import { AdminLayout } from "../shared/layout";
import { DataTable } from "@/components";
import { getWaitingList } from "@/data";


export function WaitingListScreen() {

  const { data, isLoading } = useQuery({
    queryKey: ["waitingList"],
    queryFn: getWaitingList,
  });

  const columns = [
    { key: "email", label: "E-posta" },
    { 
      key: "createdAt", 
      label: "Kayıt Tarihi",
      render: (row) => new Date(row.createdAt).toLocaleString()
    },
  ];

  if (isLoading) return <div>Loading...</div>;
  return (
    <AdminLayout
      title="Bekleme Listesi"
      description="Bekleme listesine kaydolan e-posta adresleri."
      loading={isLoading}
    >
      {data.data && (
        <DataTable
          data={data.data} 
          columns={columns}
          title="Bekleme Listesi"
          enableSearch={true}
          enableSort={true}
          enablePagination={true}
          itemsPerPage={10}
        />
      )}
    </AdminLayout>
  );
}
