import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { AppLayout } from "../shared/layout";
import { getAppEmailTemplates, deleteAppEmailTemplate } from "../../../data";
import { Button } from "@/components/ui/button";
import { Pencil, Trash2 } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useTranslation } from "react-i18next";

export function MailTemplateListScreen() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { data: mailTemplates, isLoading } = useQuery({
    queryKey: ["appEmailTemplates"],
    queryFn: getAppEmailTemplates,
  });

  const deleteMutation = useMutation({
    mutationFn: deleteAppEmailTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries(["appEmailTemplates"]);
      toast({
        title: t("common.success"),
        description: t("mailTemplateList.mailTemplateDeleted"),
        variant: "success",
      });
    },
    onError: (error) => {
      toast({
        title: t("common.error"),
        description: t("mailTemplateList.mailTemplateDeleteError"),
        variant: "destructive",
      });
      console.error("Mail şablonu silme hatası:", error);
    },
  });

  const handleDelete = (id) => {
    deleteMutation.mutate(id);
  };

  const actions = [
    {
      name: t("mailTemplateList.createNewMailTemplate"),
      onClick: () => navigate("/mail-templates/add"),
    },
  ];

  const columns = [
    { key: "name", label: t("mailTemplateList.mailTemplateName") },
    {
      key: "actions",
      label: t("common.actions"),
      render: (row) => (
        <div className="flex space-x-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => navigate(`/app/mail-templates/${row.id}`)}
            className="hover:bg-green-100"
          >
            <Pencil className="h-4 w-4 text-green-500" />
          </Button>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button variant="ghost" size="icon" className="hover:bg-red-100">
                <Trash2 className="h-4 w-4 text-red-500" />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  {t("mailTemplateList.mailTemplateDeleteConfirmationTitle")}
                </AlertDialogTitle>
                <AlertDialogDescription>
                  {t("mailTemplateList.mailTemplateDeleteConfirmation")}
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>{t("common.cancel")}</AlertDialogCancel>
                <AlertDialogAction onClick={() => handleDelete(row.id)}>
                  {t("common.delete")}
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      ),
    },
  ];

  return (
    <AppLayout
      title={t("mailTemplateList.mailTemplateList")}
      description={t("mailTemplateList.mailTemplateListDescription")}
      actions={actions}
      loading={isLoading}
    >
      {mailTemplates && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {mailTemplates.map((template) => (
            <Card key={template.id} className="w-full">
              <CardHeader>
                <CardTitle>{template.name}</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="h-64 overflow-hidden border rounded-md p-2">
                  <div className="transform scale-[0.4] origin-top-left w-[250%] h-[250%]">
                    <iframe
                      srcDoc={template.content}
                      title={`Preview of ${template.name}`}
                      className="w-full h-full pointer-events-none"
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                      }}
                    />
                  </div>
                </div>
              </CardContent>
              <CardFooter className="flex justify-end space-x-2">
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => navigate(`/mail-templates/${template.id}`)}
                  className="hover:bg-green-100"
                >
                  <Pencil className="h-4 w-4 text-green-500 mr-2" />
                  {t("common.edit")}
                </Button>
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="hover:bg-red-100"
                    >
                      <Trash2 className="h-4 w-4 text-red-500 mr-2" />
                      {t("common.delete")}
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>
                        {t(
                          "mailTemplateList.mailTemplateDeleteConfirmationTitle"
                        )}
                      </AlertDialogTitle>
                      <AlertDialogDescription>
                        {t("mailTemplateList.mailTemplateDeleteConfirmation")}
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>
                        {t("common.cancel")}
                      </AlertDialogCancel>
                      <AlertDialogAction
                        onClick={() => handleDelete(template.id)}
                      >
                        {t("common.delete")}
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </CardFooter>
            </Card>
          ))}
        </div>
      )}
    </AppLayout>
  );
}
