import React from 'react';
import { Card, CardHeader, CardTitle, CardContent, Button } from '@/components/ui';
import { ExternalLink } from 'lucide-react';

export function GoogleAccountManagement() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Google Hesap Yönetimi</CardTitle>
      </CardHeader>
      <CardContent>
        <p className="mb-4">
          Bu hesap Google ile bağlantılıdır. Hesap güvenliğinizi yönetmek için Google hesap ayarlarınızı ziyaret edin.
        </p>
        <Button
          variant="outline"
          className="flex items-center"
          onClick={() => window.open('https://myaccount.google.com/security', '_blank', 'noopener,noreferrer')}
        >
          Google Hesap Güvenliği
          <ExternalLink className="ml-2 h-4 w-4" />
        </Button>
      </CardContent>
    </Card>
  );
}