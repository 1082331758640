import { Link, useNavigate } from "react-router-dom";
import AuthStore from "../../stores/AuthStore";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "@/components/ui/button";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { User } from "lucide-react";
import { useTranslation } from "react-i18next";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const routesMenu = [
  {
    displayName: "appMenu.home",
    path: "/dashboard",
  },
  {
    displayName: "appMenu.documents",
    path: "/documents",
  },
  {
    displayName: "appMenu.emailAccounts",
    path: "/email-accounts",
  },
  {
    displayName: "appMenu.emailTemplates",
    path: "/mail-templates",
  },
];

export function NavbarApp() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { i18n, t } = useTranslation();

  const logout = () => {
    queryClient.clear();
    AuthStore.logout();
    navigate("/");
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <nav className="border-b-2">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link
              className="flex title-font font-medium items-center text-gray-900"
              to="/"
            >
              Send Excel
            </Link>

            <NavigationMenu className="ml-10">
              <NavigationMenuList>
                {routesMenu.map((route) => (
                  <NavigationMenuItem key={route.path}>
                    <NavigationMenuLink asChild>
                      <Link
                        to={route.path}
                        className={navigationMenuTriggerStyle()}
                      >
                        {t(route.displayName)}
                      </Link>
                    </NavigationMenuLink>
                  </NavigationMenuItem>
                ))}
              </NavigationMenuList>
            </NavigationMenu>
          </div>

          <div className="flex items-center space-x-4">
            <Select onValueChange={changeLanguage} defaultValue={i18n.language}>
              <SelectTrigger className="w-[60px]">
                <SelectValue placeholder={t("language")} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="en">{t("english")}</SelectItem>
                <SelectItem value="tr">{t("turkish")}</SelectItem>
              </SelectContent>
            </Select>

            {AuthStore.isHavePermission("ADMIN") && (
              <Button variant="outline" asChild>
                <Link to="/admin">Admin</Link>
              </Button>
            )}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  className="relative h-8 w-8 rounded-full"
                >
                  <Avatar className="h-8 w-8">
                    <AvatarImage
                      src="/path-to-your-avatar-image.jpg"
                      alt="@username"
                    />
                    <AvatarFallback>
                      <User className="h-4 w-4" />
                    </AvatarFallback>
                  </Avatar>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56" align="end" forceMount>
                <DropdownMenuLabel className="font-normal">
                  <div className="flex flex-col space-y-1">
                    <p className="text-sm font-medium leading-none">username</p>
                    <p className="text-xs leading-none text-muted-foreground">
                      user@example.com
                    </p>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem asChild>
                  <Link to="/profile">Profile</Link>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={logout}>Log out</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
    </nav>
  );
}
