import React, { useState, useEffect } from "react";
import { Upload, AlertCircle } from "lucide-react";

export function DragAndDropTextEditor({
  content,
  onSetContent,
  title,
  isSingleItem = false,
  onDropFinish,
}) {
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleDragStart = () => setIsDraggingOver(true);
    const handleDragEnd = () => setIsDraggingOver(false);

    document.addEventListener("dragstart", handleDragStart);
    document.addEventListener("dragend", handleDragEnd);

    return () => {
      document.removeEventListener("dragstart", handleDragStart);
      document.removeEventListener("dragend", handleDragEnd);
    };
  }, []);

  const handleDrop = (e) => {
    e.preventDefault();
    const headerName = e.dataTransfer.getData("text/plain");
    let newContent = content.replace(/<div><br><\/div>/g, "<br>");
    newContent = newContent.replace(/&nbsp;/g, " ");

    if (isSingleItem) {
      if (newContent.includes("${")) {
        setError(
          "Only one header is allowed. Please remove the existing header first."
        );
        setIsDraggingOver(false);
        return;
      }
      newContent = `\${${headerName}}`;
    } else {
      newContent += ` \${${headerName}}`;
    }

    onSetContent(newContent.trim());
    setError(null);
    setIsDraggingOver(false);

    if (onDropFinish) {
      onDropFinish(headerName);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleRemoveHeader = () => {
    onSetContent("");
    setError(null);
    if (onDropFinish) {
      onDropFinish(null);
    }
  };

  return (
    <div className="relative">
      <h3 className="text-sm font-medium mb-2">{title}</h3>
      <div
        contentEditable={!isSingleItem}
        className={`bg-white p-3 rounded-md shadow-sm border-2 transition-colors duration-200 ${
          isDraggingOver ? "border-blue-500" : "border-gray-300"
        }`}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        style={{
          minHeight: "100px",
        }}
        dangerouslySetInnerHTML={{ __html: content }}
        onBlur={(e) => !isSingleItem && onSetContent(e.target.innerHTML)}
      ></div>
      {isDraggingOver && (
        <div className="absolute inset-0 flex items-center justify-center bg-blue-100 bg-opacity-50 rounded-md pointer-events-none">
          <Upload className="h-8 w-8 text-blue-500 mr-2" />
          <span className="text-blue-700 font-medium">Drop header here</span>
        </div>
      )}
      {error && (
        <div className="mt-2 text-red-500 flex items-center">
          <AlertCircle className="h-4 w-4 mr-1" />
          <span>{error}</span>
        </div>
      )}
      {isSingleItem && content && content.includes("${") && (
        <button
          onClick={handleRemoveHeader}
          className="mt-2 text-sm text-red-500 hover:text-red-700"
        >
          Remove Header
        </button>
      )}
    </div>
  );
}
