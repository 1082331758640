import React from "react";

import { AppLayout, DashboardLayout } from "../shared/layout";
import { useQuery } from "@tanstack/react-query";
import { getProfile } from "../../../data";
import { GoogleAccountManagement } from "./google-account";
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui";
export function ProfileScreen() {
  const { data, isLoading, error } = useQuery({
    queryKey: ["profile"],
    queryFn: getProfile,
  });

  const pageSettings = {
    title: "Profile",
    actions: [],
  };

  return (
    <div>
      <AppLayout
        title={pageSettings.title}
        actions={pageSettings.actions}
        loading={isLoading}
      >
        {error ? (
          <div>Bu alani gorme yetkiniz yok</div>
        ) : (
          !isLoading && (
            <div className="flex flex-col gap-4">
              <div className="flex-1">
                <Card>
                  <CardHeader>
                    <CardTitle>Kullanıcı Bilgileri</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <p><strong>İsim:</strong> {data.data.user.name}</p>
                    <p><strong>E-posta:</strong> {data.data.user.email}</p>
                  </CardContent>
                </Card>
              </div>
              {data.data.user.googleId && (
                <GoogleAccountManagement />
              )}</div>
          )
        )}

      </AppLayout>
    </div>
  );
}
