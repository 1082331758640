import React from "react";

import { LandingPageLayout } from "../shared/layout";
import { PlanListComponent } from "@/screens/shared/plan-list";

export function PricingPageScreen() {
  return (
    <LandingPageLayout>
      <section className="my-10 body-font">
        <div className=" lg:pl-24  flex flex-col ">
          <h1 className="title-font  text-3xl mb-4 font-medium ">
            Pricng Page
          </h1>
          <PlanListComponent></PlanListComponent>
        </div>
      </section>
    </LandingPageLayout>
  );
}
