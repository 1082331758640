import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AppLayout } from "../shared/layout";
import { deleteDocument, getDocuments } from "../../../data";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { PlusIcon, Pencil, Trash } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { getEmailTransactions } from "@/data/requests/document-requests";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useTranslation } from "react-i18next";

export function DocumentMailTransactionListScreen() {
  const { t } = useTranslation();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [selectedMail, setSelectedMail] = useState(null);

  console.log(id);

  const { data, isLoading, error } = useQuery({
    queryKey: ["emailTransactions", id],
    queryFn: () => getEmailTransactions(id),
  });

  console.log(data);

  if (isLoading) {
    return (
      <AppLayout>
        <div className="flex justify-center items-center h-full">
          <p className="text-lg">Loading email transactions...</p>
        </div>
      </AppLayout>
    );
  }

  if (error) {
    return (
      <AppLayout>
        <div className="flex justify-center items-center h-full">
          <p className="text-lg text-red-500">
            Error loading email transactions. Please try again.
          </p>
        </div>
      </AppLayout>
    );
  }

  const handleMailClick = (mail) => {
    setSelectedMail(mail);
  };

  return (
    <AppLayout
      title={t("emailAccountList.emailTransactions")}
      actions={[
        {
          name: t("common.back"),
          icon: PlusIcon,
          onClick: () => navigate(`/documents/${id}`),
        },
      ]}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {data && data.length ? (
          data.map((item) => (
            <Card
              key={item.id}
              className="cursor-pointer"
              onClick={() => handleMailClick(item)}
            >
              <CardHeader>
                <CardTitle>
                  {t("emailAccountList.subject")}: {item.subject}
                </CardTitle>

                <CardDescription>
                  {item.isSended
                    ? t("emailAccountList.sended")
                    : t("emailAccountList.notSended")}
                </CardDescription>
              </CardHeader>
              <CardContent>
                <CardDescription>
                  {t("emailAccountList.mailTo")}: {item.to}
                </CardDescription>
                <div className="h-64 overflow-hidden border rounded-md p-2">
                  <div className="transform scale-[0.4] origin-top-left w-[250%] h-[250%]">
                    <iframe
                      srcDoc={item.mailContent}
                      title={`${t("emailAccountList.preview")} of ${
                        item.subject
                      }`}
                      className="w-full h-full pointer-events-none"
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                      }}
                    />
                  </div>
                </div>
              </CardContent>
              <CardFooter className="flex justify-end space-x-2"></CardFooter>
            </Card>
          ))
        ) : (
          <div className="col-span-full text-center p-4 bg-gray-100 rounded-md">
            <p className="text-gray-500">
              {t("emailAccountList.noEmailTransactionsFound")}
            </p>
          </div>
        )}
      </div>

      <Dialog open={!!selectedMail} onOpenChange={() => setSelectedMail(null)}>
        <DialogContent className="max-w-4xl h-[80vh] flex flex-col">
          <DialogHeader>
            <DialogTitle>
              {t("emailAccountList.subject")}: {selectedMail?.subject}
            </DialogTitle>
            <DialogDescription>
              {selectedMail?.isSended
                ? t("emailAccountList.sended")
                : t("emailAccountList.notSended")}
            </DialogDescription>
          </DialogHeader>
          <DialogDescription>
            {t("emailAccountList.mailTo")}: {selectedMail?.to}
          </DialogDescription>
          <div className="flex-grow overflow-auto mt-4">
            <iframe
              srcDoc={selectedMail?.mailContent}
              title={`${t("emailAccountList.fullView")} of ${
                selectedMail?.subject
              }`}
              className="w-full h-full border-none"
            />
          </div>
        </DialogContent>
      </Dialog>
    </AppLayout>
  );
}
