import api from "../api";

const login = (data) => {
  return api.getApiWithoutToken().post(api.getBaseUrl() + "login", data);
};
const registerUser = (data) => {
  return api.getApiWithoutToken().post(api.getBaseUrl() + "register", data);
};
const changePassword = (data) => {
  return api.getApi().post(api.getBaseUrl() + "ChangePassword", data);
};
const forgotPassword = (data) => {
  return api
    .getApiWithoutToken()
    .post(api.getBaseUrl() + "forgot-password", data);
};
const resetPassword = (data) => {
  return api
    .getApiWithoutToken()
    .post(api.getBaseUrl() + "reset-password", data);
};

export { login, registerUser, changePassword, forgotPassword, resetPassword };
