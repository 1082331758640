import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetPassword } from "../../../data";
import {
  Button,
  Input,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui";
import { useToast } from "@/components/ui/use-toast";

export function PasswordResetScreen() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const { toast } = useToast();
  const [showPassword, setShowPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();
  const password = watch("password");

  const {
    mutate: resetPasswordMutation,
    isLoading,
    error,
  } = useMutation({
    mutationKey: "resetPassword",
    mutationFn: (data) => resetPassword({ ...data, token }),
    onSuccess: () => {
      toast({
        title: "Başarılı",
        description:
          "Şifreniz başarıyla sıfırlandı. Lütfen yeni şifrenizle giriş yapın.",
        variant: "success",
      });
      navigate("/login");
    },
    onError: (error) => {
      toast({
        title: "Hata",
        description: error.message || "Şifre sıfırlama işlemi başarısız oldu.",
        variant: "destructive",
      });
    },
  });

  const onSubmit = (data) => {
    resetPasswordMutation(data);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle>Şifre Sıfırlama</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <Controller
              name="password"
              control={control}
              rules={{
                required: "Yeni şifre gereklidir",
                minLength: {
                  value: 8,
                  message: "Şifre en az 8 karakter olmalıdır",
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  type={showPassword ? "text" : "password"}
                  placeholder="Yeni Şifre"
                  error={errors.password?.message}
                />
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                required: "Şifre onayı gereklidir",
                validate: (value) =>
                  value === password || "Şifreler eşleşmiyor",
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  type={showPassword ? "text" : "password"}
                  placeholder="Şifre Onayı"
                  error={errors.confirmPassword?.message}
                />
              )}
            />
            <Button type="submit" className="w-full" disabled={isLoading}>
              {isLoading ? "Şifre Sıfırlanıyor..." : "Şifreyi Sıfırla"}
            </Button>
          </form>
          {error && <p className="text-red-500 mt-2">{error.message}</p>}
        </CardContent>
      </Card>
    </div>
  );
}
