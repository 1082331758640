import api from "../api";

export const getAppEmailTemplates = () => {
  return api
    .getApi()
    .get(api.getBaseUrl() + "mail-templates/")
    .then((res) => res.data);
};

export const getAppEmailTemplate = (id) => {
  return api.getApi().get(api.getBaseUrl() + "mail-templates/" + id);
};
export const createAppEmailTemplate = (data) => {
  return api.getApi().post(api.getBaseUrl() + "mail-templates/", data);
};
export const updateAppEmailTemplate = (id, data) => {
  return api.getApi().put(api.getBaseUrl() + "mail-templates/" + id, data);
};
export const deleteAppEmailTemplate = (id) => {
  return api.getApi().delete(api.getBaseUrl() + "mail-templates/" + id);
};
