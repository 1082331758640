import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm, Controller } from "react-hook-form";
import { AdminLayout } from "../shared/layout";
import { CardBox } from "../shared/CardBox";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import {
  getEmailTemplate,
  createEmailTemplate,
  updateEmailTemplate,
} from "@/data";

export function EmailTemplateManageScreen() {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { toast } = useToast();
  const isEditMode = !!id;
  const [iframeKey, setIframeKey] = useState(0);

  const { data: templateData, isLoading: isTemplateLoading } = useQuery({
    queryKey: ["emailTemplate", id],
    queryFn: () => getEmailTemplate(id),
    enabled: isEditMode,
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: async () => {
      if (isEditMode) {
        const response = await getEmailTemplate(id);
        return {
          name: response.data.name,
          subject: response.data.subject,
          content: response.data.content,
        };
      }
      return {
        name: "",
        subject: "",
        content: "",
      };
    },
  });

  const mutation = useMutation({
    mutationFn: (data) =>
      isEditMode ? updateEmailTemplate(id, data) : createEmailTemplate(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["emailTemplates"]);
      toast({
        title: "Başarılı",
        description: isEditMode
          ? "E-posta şablonu güncellendi"
          : "E-posta şablonu oluşturuldu",
        variant: "success",
      });
      navigate("/admin/email-templates");
    },
    onError: (error) => {
      toast({
        title: "Hata",
        description: "Bir hata oluştu",
        variant: "destructive",
      });
      console.error("E-posta şablonu işlem hatası:", error);
    },
  });

  const onSubmit = (data) => {
    mutation.mutate(data);
  };

  // Watch for changes in the form fields
  const subject = watch("subject");
  const content = watch("content");

  // Update iframe content when subject or content changes
  useEffect(() => {
    setIframeKey((prevKey) => prevKey + 1);
  }, [subject, content]);

  if (isTemplateLoading && isEditMode) {
    return (
      <AdminLayout title="E-posta Şablonu Yönetimi">Yükleniyor...</AdminLayout>
    );
  }

  return (
    <AdminLayout
      title={
        isEditMode ? "E-posta Şablonu Güncelle" : "Yeni E-posta Şablonu Ekle"
      }
    >
      <div className="flex gap-8">
        <div className="w-1/2">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
            <CardBox>
              <h2 className="text-2xl font-bold mb-4">
                E-posta Şablonu Bilgileri
              </h2>
              <div className="space-y-4">
                <div>
                  <Label htmlFor="name">Şablon Adı</Label>
                  <Controller
                    name="name"
                    control={control}
                    rules={{ required: "Şablon adı gereklidir" }}
                    render={({ field }) => <Input id="name" {...field} />}
                  />
                  {errors.name && (
                    <p className="text-red-500">{errors.name.message}</p>
                  )}
                </div>

                <div>
                  <Label htmlFor="subject">Konu</Label>
                  <Controller
                    name="subject"
                    control={control}
                    rules={{ required: "Konu gereklidir" }}
                    render={({ field }) => <Input id="subject" {...field} />}
                  />
                  {errors.subject && (
                    <p className="text-red-500">{errors.subject.message}</p>
                  )}
                </div>

                <div>
                  <Label htmlFor="content">İçerik</Label>
                  <Controller
                    name="content"
                    control={control}
                    rules={{ required: "İçerik gereklidir" }}
                    render={({ field }) => (
                      <Textarea id="content" {...field} rows={10} />
                    )}
                  />
                  {errors.content && (
                    <p className="text-red-500">{errors.content.message}</p>
                  )}
                </div>
              </div>
            </CardBox>

            <Button type="submit" className="w-full">
              {isEditMode
                ? "E-posta Şablonu Güncelle"
                : "E-posta Şablonu Oluştur"}
            </Button>
          </form>
        </div>
        <div className="w-1/2">
          <CardBox>
            <h2 className="text-2xl font-bold mb-4">E-posta Önizleme</h2>
            <div className="border rounded-md p-4 bg-white">
              <h3 className="font-bold mb-2">Konu: {subject}</h3>
              <iframe
                key={iframeKey}
                srcDoc={`<html><body>${content}</body></html>`}
                title="Email Preview"
                className="w-full h-[500px] border-none"
                sandbox="allow-scripts"
              />
            </div>
          </CardBox>
        </div>
      </div>
    </AdminLayout>
  );
}
