import React, { useState } from "react";
import { AppLayout } from "../shared/layout";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { uploadExcel } from "../../../data";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { AlertCircle } from "lucide-react";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useToast } from "@/components/ui/use-toast";
import { useTranslation } from "react-i18next";

export function DocumentAddScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const mutation = useMutation({
    mutationKey: ["excelUpload"],
    mutationFn: (data) => uploadExcel(data),
    onSuccess: () => {
      navigate("/documents");
      toast({
        title: t("documentList.documentUploadSuccess"),
        variant: "default",
      });
    },
    onError: (error) => {
      toast({
        title: t("documentList.documentUploadFailed"),
        variant: "destructive",
      });
    },
  });

  const onSubmit = async (data) => {
    const { name, excelFile } = data;
    const formData = new FormData();
    formData.append("file", excelFile[0]);
    formData.append("name", name);
    mutation.mutate(formData);
  };

  if (mutation.isPending) {
    return (
      <AppLayout title={t("documentList.createNewDocument")} loading={true} />
    );
  }

  return (
    <AppLayout title={t("documentList.createNewDocument")}>
      <Card className="w-full max-w-md mx-auto">
        <CardHeader>
          <CardTitle>{t("documentList.createNewDocument")}</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="name">{t("documentList.documentName")}</Label>
              <Input
                id="name"
                type="text"
                placeholder={t("documentList.documentNamePlaceholder")}
                {...register("name", {
                  required: t("documentList.documentNameRequired"),
                })}
              />
              {errors.name && (
                <Alert variant="destructive">
                  <AlertCircle className="h-4 w-4" />
                  <AlertDescription>{errors.name.message}</AlertDescription>
                </Alert>
              )}
            </div>
            <div className="space-y-2">
              <Label htmlFor="excelFile">Excel File</Label>
              <Input
                id="excelFile"
                type="file"
                accept=".xlsx,.xls"
                {...register("excelFile", {
                  required: "Excel file is required",
                })}
              />
              {errors.excelFile && (
                <Alert variant="destructive">
                  <AlertCircle className="h-4 w-4" />
                  <AlertDescription>
                    {errors.excelFile.message}
                  </AlertDescription>
                </Alert>
              )}
            </div>
            <Button
              type="submit"
              className="w-full"
              disabled={mutation.isLoading}
            >
              {mutation.isLoading
                ? t("common.loading")
                : t("documentList.uploadDocument")}
            </Button>
          </form>
        </CardContent>
      </Card>
    </AppLayout>
  );
}
