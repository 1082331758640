import api from "../api";

export const uploadExcel = (data) => {
  return api
    .getApi()
    .post(api.getBaseUrl() + "excel/uploadExcel", data)
    .then((res) => {
      return res.data;
    });
};

export const getDocument = (id) => {
  return api
    .getApi()
    .get(api.getBaseUrl() + "documents/" + id)
    .then((res) => {
      return res.data;
    });
};

export const updateDocument = (id, data) => {
  return api
    .getApi()
    .put(api.getBaseUrl() + "documents/" + id, data)
    .then((res) => {
      return res.data;
    });
};

export const getDocuments = () => {
  return api
    .getApi()
    .get(api.getBaseUrl() + "documents")
    .then((res) => {
      return res.data;
    });
};

export const deleteDocument = (id) => {
  return api
    .getApi()
    .delete(api.getBaseUrl() + "documents/" + id)
    .then((res) => {
      return res.data;
    });
};

export const createEmailTransaction = (id) => {
  return api
    .getApi()
    .post(api.getBaseUrl() + "documents/" + id + "/createEmailTransaction")
    .then((res) => {
      return res.data;
    });
};

export const getEmailTransactions = async (id) => {
  const response = await api
    .getApi()
    .get(api.getBaseUrl() + "documents/" + id + "/email-transactions");
  return response.data;
};

export const sendMail = async (id) => {
  const response = await api
    .getApi()
    .post(api.getBaseUrl() + "documents/" + id + "/send-mail");
  return response.data;
};
