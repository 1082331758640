import api from "../api";

export const getSubscriptions = async () => {
  const response = await api.getApi().get(api.getBaseUrl() + "subscriptions");
  return response.data;
};

export const deleteSubscription = async (id) => {
  const response = await api
    .getApi()
    .delete(api.getBaseUrl() + "subscriptions/" + id);
  return response.data;
};

export const updateSubscription = async (id, data) => {
  const response = await api
    .getApi()
    .put(api.getBaseUrl() + `subscriptions/${id}`, data);
  return response.data;
};

export const createSubscription = async (data) => {
  const response = await api
    .getApi()
    .post(api.getBaseUrl() + "subscriptions", data);
  return response.data;
};

export const getSubscription = async (id) => {
  const response = await api
    .getApi()
    .get(api.getBaseUrl() + `subscriptions/${id}`);
  return response.data;
};
