import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AppLayout } from "../shared/layout";
import { deleteDocument, getDocuments } from "../../../data";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { PlusIcon, Pencil, Trash } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useTranslation } from "react-i18next";

export function DocumentListScreen() {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { toast } = useToast();

  const { data, isLoading, error } = useQuery({
    queryKey: ["documents"],
    queryFn: getDocuments,
    retry: 1,
  });

  const mutation = useMutation({
    mutationKey: (data) => ["deleteDocument", data],
    mutationFn: (data) => deleteDocument(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["documents"]);
      toast({
        title: t("documentList.documentDeleted"),
        description: t("documentList.documentDeletedDescription"),
      });
    },
    onError: (error) => {
      toast({
        title: t("common.error"),
        description: t("documentList.documentDeletedError"),
        variant: "destructive",
      });
      console.error("Delete document error:", error);
    },
  });

  const goToEdit = (id) => () => navigate(`/documents/${id}`);

  const onDeleteDocument = (id) => {
    mutation.mutate(id);
  };

  if (isLoading) {
    return (
      <AppLayout>
        <div className="flex justify-center items-center h-full">
          <p className="text-lg">{t("common.loading")}</p>
        </div>
      </AppLayout>
    );
  }

  if (error) {
    return (
      <AppLayout>
        <div className="flex justify-center items-center h-full">
          <p className="text-lg text-red-500">
            {t("common.error")} {t("documentList.documentLoadingError")}
          </p>
        </div>
      </AppLayout>
    );
  }

  return (
    <AppLayout
      title={t("documentList.documentList")}
      actions={[
        {
          name: t("documentList.createNewDocument"),
          icon: PlusIcon,
          onClick: () => navigate("/document/add"),
        },
      ]}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {data && data.length ? (
          data.map((item) => (
            <Card key={item.id}>
              <CardHeader>
                <CardTitle>{item.name}</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-sm text-gray-500">File: {item.fileName}</p>
              </CardContent>
              <CardFooter className="flex justify-end space-x-2">
                <Button variant="outline" size="sm" onClick={goToEdit(item.id)}>
                  <Pencil className="mr-2 h-4 w-4" />
                  {t("common.edit")}
                </Button>
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button variant="destructive" size="sm">
                      <Trash className="mr-2 h-4 w-4" />
                      {t("common.delete")}
                    </Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>
                        {t("documentList.documentDeleteConfirmationTitle")}
                      </AlertDialogTitle>
                      <AlertDialogDescription>
                        {t("documentList.documentDeleteConfirmation")}
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>
                        {t("common.cancel")}
                      </AlertDialogCancel>
                      <AlertDialogAction
                        onClick={() => onDeleteDocument(item.id)}
                      >
                        {t("common.delete")}
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </CardFooter>
            </Card>
          ))
        ) : (
          <div className="col-span-full text-center p-4 bg-gray-100 rounded-md">
            <p className="text-gray-500">
              {t("documentList.noDocumentsFound")}
            </p>
          </div>
        )}
      </div>
    </AppLayout>
  );
}
