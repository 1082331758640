import React, { useEffect, useRef, useState } from "react";
import grapesjs from "grapesjs";
import gjsPresetNewsletter from "grapesjs-preset-newsletter";
import "grapesjs/dist/css/grapes.min.css";

import { Button, Input } from "../ui";
import { generateHtml } from "@/data/requests/ai-requests";

export function AdvancedEmailEditor({
  content,
  onSetContent,
  title,
  minHeight,
  headers,
  templates,
}) {
  const editorRef = useRef(null);
  const [editor, setEditor] = useState(null);
  const [selectedHtml, setSelectedHtml] = useState("");
  const [chatGptPrompt, setChatGptPrompt] = useState("");

  // Editor initialization
  useEffect(() => {
    if (!editorRef.current) {
      const e = grapesjs.init({
        container: "#gjs",
        plugins: [gjsPresetNewsletter],
        pluginsOpts: {
          gjsPresetNewsletter: {},
        },
        storageManager: false,
        height: minHeight || "500px",
        width: "auto",

        panels: {},
        canvas: {
          styles: [
            "https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css",
          ],
        },

        // Enable editing for all text components
        domComponents: {
          defaults: {
            editable: true,
            droppable: true,
            draggable: true,
            selectable: true,
            hoverable: true,
          },
        },
      });

      e.on("load", () => {
        setEditor(e);
        editorRef.current = e;

        // Enable dragging for text components
        const textComponents = [
          "text",
          "paragraph",
          "heading",
          "heading1",
          "heading2",
          "heading3",
          "heading4",
          "heading5",
          "heading6",
        ];
        textComponents.forEach((type) => {
          e.DomComponents.addType(type, {
            model: {
              defaults: {
                draggable: true,
                droppable: true,
              },
            },
          });
        });
      });
    }

    return () => {
      editorRef.current?.destroy();
      editorRef.current = null;
    };
  }, [minHeight]);

  // Editor configuration and content setting
  useEffect(() => {
    if (editor) {
      // Add custom block for Excel headers
      if (headers) {
        headers.forEach((header) => {
          editor.BlockManager.add(header.name, {
            label: header.name,
            content: `<span data-gjs-type="excel-header" data-header-name="${header.name}">\${${header.name}}</span>`,
            category: "Excel Headers",
          });
        });

        // Custom component type for Excel headers
        editor.DomComponents.addType("excel-header", {
          model: {
            defaults: {
              type: "text",
              draggable: true,
              droppable: false,
              editable: false,
              hoverable: false,
              selectable: false,
              tagName: "span",

              traits: [
                {
                  type: "text",
                  name: "headerName",
                  label: "Header Name",
                },
              ],
            },
          },
          view: {
            events: {
              dblclick: "onActive",
            },
            onActive(ev) {
              this.model.trigger("active");
              ev.stopPropagation();
            },
          },
        });
      }

      // Add custom block for Templates
      if (templates) {
        templates.forEach((template) => {
          editor.BlockManager.add(template.name, {
            label: template.name,
            content: {
              type: "template",
              templateName: template.name,
              content: template.content,
            },
            category: "Templates",
          });
        });
      }

      editor.on("component:selected", (component) => {
        if (component) {
          setSelectedHtml(component.toHTML());
        }
      });

      editor.on("component:updated", (component) => {
        if (component === editor.getSelected()) {
          setSelectedHtml(component.toHTML());
        }
      });

      // Handle template addition
      editor.on("block:drag:stop", (component) => {
        if (component && component.get("type") === "template") {
          const content = component.get("content");
          if (content) {
            editor.setComponents(content);
            // Remove the template component itself
            component.remove();
          }
        }
      });

      // Set initial content
      if (content) {
        editor.setComponents(content);
      }
    }
  }, [editor, headers, templates, content, onSetContent]);

  const askChatGptForHtml = async () => {
    if (!chatGptPrompt || !editor) return;

    try {
      const response = await generateHtml({
        prompt: `my prompt is: ${chatGptPrompt}.  My current html is : ${selectedHtml}`,
      });
      let generatedHtml = response.data.html;

      // Remove markdown formatting
      generatedHtml = generatedHtml.replace(/```html/g, "").replace(/```/g, "");

      const selectedComponent = editor.getSelected();
      if (selectedComponent) {
        // Remove any existing body tags
        generatedHtml = generatedHtml.replace(/<\/?body[^>]*>/g, "");

        // Replace the content of the selected component
        selectedComponent.components(generatedHtml);

        // Update the editor
        editor.refresh();

        // Make all child components selectable
        selectedComponent.components().forEach((comp) => {
          comp.set({
            selectable: true,
            hoverable: true,
            draggable: true,
            editable: true,
          });
        });

        // Trigger a change event
        editor.trigger("component:update", selectedComponent);
      }
    } catch (error) {
      console.error("Error asking ChatGPT:", error);
    }
  };

  return (
    <div>
      <h3>{title}</h3>
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div
          id="gjs"
          style={{ flexGrow: 1, minHeight: minHeight || "500px" }}
        ></div>
        <div className="mt-4">
          <Input
            value={chatGptPrompt}
            onChange={(e) => setChatGptPrompt(e.target.value)}
            placeholder="Enter prompt for ChatGPT"
          />
          <Button type="button" onClick={askChatGptForHtml} className="mt-2">
            Ask ChatGPT
          </Button>
        </div>
        <div>
          <Button
            type="button"
            onClick={() => {
              const htmlContent = editor.getHtml();
              const cssContent = editor.getCss();
              onSetContent(`<style>${cssContent}</style>${htmlContent}`);
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
