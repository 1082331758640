import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { AdminLayout } from "../shared/layout";
import { DataTable } from "@/components";
import { getEmailTemplates, deleteEmailTemplate } from "@/data";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Pencil, Trash2 } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

export function EmailTemplateListScreen() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const { data: emailTemplates, isLoading } = useQuery({
    queryKey: ["emailTemplates"],
    queryFn: getEmailTemplates,
  });

  const deleteMutation = useMutation({
    mutationFn: deleteEmailTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries(["emailTemplates"]);
      toast({
        title: "Başarılı",
        description: "E-posta şablonu silindi",
        variant: "success",
      });
    },
    onError: (error) => {
      toast({
        title: "Hata",
        description: "E-posta şablonu silinirken bir hata oluştu",
        variant: "destructive",
      });
      console.error("E-posta şablonu silme hatası:", error);
    },
  });

  const handleDelete = (id) => {
    deleteMutation.mutate(id);
  };

  const actions = [
    {
      name: "Yeni E-posta Şablonu",
      onClick: () => navigate("/admin/email-templates/new"),
    },
  ];

  const columns = [
    { key: "name", label: "Şablon Adı" },
    { key: "subject", label: "Konu" },
    {
      key: "actions",
      label: "İşlemler",
      render: (row) => (
        <div className="flex space-x-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => navigate(`/admin/email-templates/${row.id}`)}
            className="hover:bg-green-100"
          >
            <Pencil className="h-4 w-4 text-green-500" />
          </Button>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button variant="ghost" size="icon" className="hover:bg-red-100">
                <Trash2 className="h-4 w-4 text-red-500" />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>E-posta Şablonunu Sil</AlertDialogTitle>
                <AlertDialogDescription>
                  Bu e-posta şablonunu silmek istediğinizden emin misiniz? Bu
                  işlem geri alınamaz.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>İptal</AlertDialogCancel>
                <AlertDialogAction onClick={() => handleDelete(row.id)}>
                  Sil
                </AlertDialogAction>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      ),
    },
  ];

  return (
    <AdminLayout
      title="E-posta Şablonları"
      description="E-posta şablonlarınızı buradan yönetin."
      actions={actions}
      loading={isLoading}
    >
      {emailTemplates && (
        <DataTable
          data={emailTemplates}
          columns={columns}
          title="E-posta Şablonu Listesi"
          enableSearch={true}
          enableSort={true}
          enablePagination={true}
          itemsPerPage={10}
        />
      )}
    </AdminLayout>
  );
}
