import api from "../api";

export const getEmailTemplates = () => {
  return api
    .getApi()
    .get(api.getBaseUrl() + "email-templates/")
    .then((res) => res.data);
};

export const getEmailTemplate = (id) => {
  return api.getApi().get(api.getBaseUrl() + "email-templates/" + id);
};
export const createEmailTemplate = (data) => {
  return api.getApi().post(api.getBaseUrl() + "email-templates/", data);
};
export const updateEmailTemplate = (id, data) => {
  return api.getApi().put(api.getBaseUrl() + "email-templates/" + id, data);
};
export const deleteEmailTemplate = (id) => {
  return api.getApi().delete(api.getBaseUrl() + "email-templates/" + id);
};
