import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm, Controller } from "react-hook-form";
import { AppLayout } from "../shared/layout";
import {
  getAppEmailTemplate,
  createAppEmailTemplate,
  updateAppEmailTemplate,
} from "../../../data";
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { AdvancedEmailEditor } from "@/components/mail/advance-email-editor";
import { useTranslation } from "react-i18next";

export function MailTemplateManageScreen() {
  const { t } = useTranslation();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { toast } = useToast();
  const isEditMode = !!id;

  const { data: templateData, isLoading: isTemplateLoading } = useQuery({
    queryKey: ["appEmailTemplate", id],
    queryFn: () => getAppEmailTemplate(id),
    enabled: isEditMode,
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: React.useMemo(
      () => ({
        name: templateData?.data?.name || "",
        content: templateData?.data?.content || "",
      }),
      [templateData?.data]
    ),
  });
  React.useEffect(() => {
    if (templateData?.data) {
      reset({
        name: templateData.data.name,
        content: templateData.data.content,
      });
    }
  }, [templateData?.data]);

  const mutation = useMutation({
    mutationFn: (data) =>
      isEditMode
        ? updateAppEmailTemplate(id, data)
        : createAppEmailTemplate(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["appEmailTemplates"]);
      toast({
        title: t("common.success"),
        description: isEditMode
          ? t("mailTemplateList.mailTemplateUpdated")
          : "Mail şablonu oluşturuldu",
        variant: "success",
      });
      navigate("/mail-templates");
    },
    onError: (error) => {
      toast({
        title: t("common.error"),
        description: t("common.errorMessage"),
        variant: "destructive",
      });
      console.error("Mail şablonu işlem hatası:", error);
    },
  });

  const onSubmit = (data) => {
    mutation.mutate(data);
  };

  if (isTemplateLoading && isEditMode) {
    return (
      <AppLayout title={t("mailTemplateList.mailTemplateDetail")}>
        {t("common.loading")}
      </AppLayout>
    );
  }

  return (
    <AppLayout
      title={
        isEditMode
          ? t("mailTemplateList.mailTemplateUpdate")
          : t("mailTemplateList.createNewMailTemplate")
      }
    >
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        <Card>
          <CardHeader>
            <CardTitle>
              {isEditMode
                ? t("mailTemplateList.mailTemplateUpdate")
                : t("mailTemplateList.createNewMailTemplate")}
            </CardTitle>
          </CardHeader>
          <CardContent className="space-y-4">
            <div>
              <Label htmlFor="name">
                {t("mailTemplateList.mailTemplateName")}
              </Label>
              <Controller
                name="name"
                control={control}
                defaultValue={templateData?.name || ""}
                rules={{ required: t("mailTemplateList.nameRequired") }}
                render={({ field }) => <Input id="name" {...field} />}
              />
              {errors.name && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.name.message}
                </p>
              )}
            </div>

            <div>
              <Label htmlFor="content">
                {t("mailTemplateList.mailTemplateContent")}
              </Label>
              <Controller
                name="content"
                control={control}
                rules={{ required: t("mailTemplateList.contentRequired") }}
                render={({ field }) => (
                  <AdvancedEmailEditor
                    id="content"
                    content={templateData?.data?.content}
                    onSetContent={field.onChange}
                  />
                )}
              />
              {errors.content && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.content.message}
                </p>
              )}
            </div>
          </CardContent>
        </Card>

        <Button type="submit" className="w-full">
          {isEditMode
            ? t("mailTemplateList.mailTemplateUpdate")
            : t("mailTemplateList.createNewMailTemplate")}
        </Button>
      </form>
    </AppLayout>
  );
}
