import api from "../api";

export const getEmailAccounts = (data) => {
  return api
    .getApi()
    .get(api.getBaseUrl() + "emailAccounts")
    .then((res) => res.data);
};
export const addEmailAccount = (data) => {
  return api
    .getApi()
    .post(api.getBaseUrl() + "emailAccounts", data)
    .then((res) => res.data);
};
export const updateEmailAccount = (id, data) => {
  return api
    .getApi()
    .put(api.getBaseUrl() + "emailAccounts/" + id, data)
    .then((res) => res.data);
};
export const getEmailAccount = (id) => {
  return api.getApi().get(api.getBaseUrl() + "emailAccounts/" + id);
};

export const deleteEmailAccount = (id) => {
  return api
    .getApi()
    .delete(api.getBaseUrl() + "emailAccounts/" + id)
    .then((res) => res.data);
};
