import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import { AppLayout } from "../shared/layout";
import { getDocument, startSendingMail } from "../../../data";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { useToast } from "@/components/ui/use-toast";
import { Mail, List, Users, FileSpreadsheet, Clock } from "lucide-react";

export function DocumentManageScreen() {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const { data, isLoading, error } = useQuery({
    queryKey: ["document", id],
    queryFn: () => getDocument(id),
  });

  if (isLoading) {
    return <AppLayout title="Document Management">Loading...</AppLayout>;
  }

  if (error) {
    return (
      <AppLayout title="Document Management">Error: {error.message}</AppLayout>
    );
  }

  return (
    <AppLayout title={`Document Management - ${data.name}`}>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Total Rows</CardTitle>
            <Users className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{data.rowCount}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Mail Status</CardTitle>
            <Mail className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              <Badge
                variant={
                  data.mailSendingStatus === "COMPLETED"
                    ? "success"
                    : "secondary"
                }
              >
                {data.mailSendingStatus}
              </Badge>
            </div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">File Name</CardTitle>
            <FileSpreadsheet className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{data.fileName}</div>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">Created At</CardTitle>
            <Clock className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">
              {new Date(data.createdAt).toLocaleDateString()}
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="mt-6 flex space-x-4">
        <Button
          onClick={() => console.log("start sending mail")}
          disabled={
            data.mailSendingStatus === "COMPLETED" ||
            data.mailSendingStatus === "IN_PROGRESS"
          }
        >
          <Mail className="mr-2 h-4 w-4" />
          Start Sending Mail
        </Button>
        {data.mailSendingStatus === "COMPLETED" && (
          <Button onClick={() => navigate(`/documents/${id}/transactions`)}>
            <List className="mr-2 h-4 w-4" />
            Show Transaction List
          </Button>
        )}
      </div>
    </AppLayout>
  );
}
