import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AppLayout } from "../shared/layout";
import { deleteEmailAccount, getEmailAccounts, getUsers } from "../../../data";
import { ItemCard, UserCard } from "../../../components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const apiUrl = process.env.REACT_APP_API_URL;

export function MailAccountListScreen() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data, isLoading } = useQuery({
    queryKey: ["emailAccounts"],
    queryFn: getEmailAccounts,
  });

  const pageSettings = {
    title: t("emailAccountList.emailAccountList"),
    actions: [
      {
        name: t("emailAccountList.createNewEmailAccount"),
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          navigate("/email-accounts/add");
        },
      },
      {
        name: t("emailAccountList.connectGmailAccount"),
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          //how to send jwt token to server
          const token = localStorage.getItem("token");
          window.location.href = `${apiUrl}auth/gmail?token=${token}`;
        },
      },
    ],
  };

  const mutation = useMutation({
    mutationKey: ["deleteEmailAccount"],
    mutationFn: (data) => {
      return deleteEmailAccount(data);
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries(["emailAccounts"]);
    },
  });

  const goToEdit = (id) => () => {
    navigate(`/email-accounts/${id}`);
  };

  const onDeleteEmailAccount = (id) => {
    if (window.confirm(t("emailAccountList.emailAccountDeleteConfirmation"))) {
      mutation.mutate(id);
    }
  };

  if (isLoading) {
    return (
      <AppLayout>
        <div>Loading...</div>
      </AppLayout>
    );
  }

  return (
    <AppLayout
      title={pageSettings.title}
      actions={pageSettings.actions}
      loading={isLoading}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {data.length ? (
          data.map((item) => (
            <ItemCard
              key={item.id}
              title={item.name}
              subtitle={`Type: ${item.to}`}
              editClick={goToEdit(item.id)}
              deleteClick={() => onDeleteEmailAccount(item.id)}
            />
          ))
        ) : (
          <div className="col-span-full bg-red-300 p-4 rounded">
            {t("emailAccountList.noEmailAccountsFound")}
          </div>
        )}
      </div>
    </AppLayout>
  );
}
