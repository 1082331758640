import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../../data";
import {
  Button,
  Input,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui";
import { useToast } from "@/components/ui/use-toast";

export function ForgotPasswordScreen() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mutate: forgotPasswordMutation, isLoading } = useMutation({
    mutationKey: "forgotPassword",
    mutationFn: forgotPassword,
    onSuccess: () => {
      toast({
        title: "Başarılı",
        description:
          "Şifre sıfırlama bağlantısı e-posta adresinize gönderildi.",
        variant: "success",
      });
      navigate("/login");
    },
    onError: (error) => {
      toast({
        title: "Hata",
        description: error.message || "Şifre sıfırlama işlemi başarısız oldu.",
        variant: "destructive",
      });
    },
  });

  const onSubmit = (data) => {
    forgotPasswordMutation(data);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle>Şifremi Unuttum</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <Controller
              name="email"
              control={control}
              rules={{
                required: "E-posta gereklidir",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Geçerli bir e-posta adresi giriniz",
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  type="email"
                  placeholder="E-posta"
                  error={errors.email?.message}
                />
              )}
            />
            <Button type="submit" className="w-full" disabled={isLoading}>
              {isLoading
                ? "Gönderiliyor..."
                : "Şifre Sıfırlama Bağlantısı Gönder"}
            </Button>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
