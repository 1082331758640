import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { submitEmail } from "@/data";
import { useToast } from "@/components/ui/use-toast";

export function WaitingPageScreen() {
  const [email, setEmail] = useState("");
  const { toast } = useToast();

  const mutation = useMutation({
    mutationFn: submitEmail,
    onSuccess: () => {
      toast({
        title: "Başarılı!",
        description: "Harika! Bekleme listemize eklendiniz.",
        variant: "default",
      });
      setEmail("");
    },
    onError: (error) => {
      toast({
        title: "Hata",
        description: `Üzgünüz, bir hata oluştu: ${error.response.data.message}`,
        variant: "destructive",
      });
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(email);
  };

  return (
    <section className="min-h-screen flex items-center justify-center bg-gradient-to-r from-indigo-500 to-purple-600">
      <div className="max-w-4xl mx-auto p-8 bg-white rounded-xl shadow-2xl">
        <h1 className="text-5xl font-extrabold text-center text-gray-800 mb-6">
          Yeni Nesil Uygulamamız Geliyor!
        </h1>
        <p className="text-xl text-center text-gray-600 mb-8">
          Hayatınızı kolaylaştıracak yenilikçi çözümümüzü ilk deneyenlerden olun.
        </p>
        
        <div className="flex justify-center mb-10">
          <img
            className="w-full max-w-lg rounded-lg shadow-lg"
            alt="Ürün önizleme"
            src="https://dummyimage.com/800x450/6366f1/ffffff&text=Yenilikçi+Ürünümüz"
          />
        </div>

        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Öne Çıkan Özellikler:</h2>
          <ul className="text-lg text-gray-600">
            <li>✨ Kullanıcı dostu arayüz</li>
            <li>🚀 Hızlı ve verimli performans</li>
            <li>🔒 Gelişmiş güvenlik</li>
            <li>📱 Tüm cihazlarda kusursuz deneyim</li>
          </ul>
        </div>

        <form onSubmit={handleSubmit} className="flex flex-col items-center">
          <input
            className="w-full max-w-md px-4 py-3 text-gray-700 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
            placeholder="E-posta adresinizi girin"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button
            className="mt-4 px-8 py-3 text-lg font-semibold text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 transition duration-300 ease-in-out"
            type="submit"
            disabled={mutation.isLoading}
          >
            {mutation.isLoading ? "Kaydediliyor..." : "Erken Erişim İçin Kaydol"}
          </button>
        </form>

        {mutation.isError && (
          <p className="mt-4 text-center text-red-500">
            Bir hata oluştu. Lütfen tekrar deneyin.
          </p>
        )}

        <p className="mt-8 text-center text-gray-500">
          Lansman tarihimiz yaklaştıkça size haber vereceğiz. Bizi takip etmeye devam edin!
        </p>
      </div>
    </section>
  );
}
