import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useObserver } from "mobx-react";
import AuthStore from "../../stores/AuthStore";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "@/components/ui/button";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Menu } from "lucide-react";

export function NavbarAdmin() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const adminRoutesMenu = [
    {
      displayName: "Users",
      path: "/admin/users",
      isHavePermission: AuthStore.isHavePermission("Permission_UserRead"),
    },
    {
      displayName: "Roles",
      path: "/admin/roles",
      isHavePermission: AuthStore.isHavePermission("Permission_RoleRead"),
    },
    {
      displayName: "Plans",
      path: "/admin/plans",
      isHavePermission: AuthStore.isHavePermission("Permission_PlanRead"),
    },

    {
      displayName: "Subscriptions",
      path: "/admin/subscriptions",
      isHavePermission: AuthStore.isHavePermission(
        "Permission_SubscriptionRead"
      ),
    },
    {
      displayName: "Settings",
      path: "/admin/settings",
      isHavePermission: AuthStore.isHavePermission("Permission_SuperAdmin"),
    },
    {
      displayName: "Waiting List",
      path: "/admin/waitinglist",
      isHavePermission: AuthStore.isHavePermission(
        "Permission_WaitingListRead"
      ),
    },
    {
      displayName: "Email Templates",
      path: "/admin/email-templates",
      isHavePermission: AuthStore.isHavePermission(
        "Permission_EmailTemplateRead"
      ),
    },
  ];

  const logout = () => {
    queryClient.clear();
    AuthStore.logout();
    navigate("/");
  };

  const NavContent = () => (
    <div className="flex flex-col h-full">
      <div className="flex-1 py-4">
        <div className="px-3 py-2">
          <h2 className="mb-2 px-4 text-lg font-semibold tracking-tight">
            Admin Panel
          </h2>
          <div className="space-y-1">
            {adminRoutesMenu.map(
              (route) =>
                route.isHavePermission && (
                  <Button
                    key={route.path}
                    variant="ghost"
                    className="w-full justify-start"
                    asChild
                  >
                    <Link to={route.path}>{route.displayName}</Link>
                  </Button>
                )
            )}
          </div>
        </div>
      </div>
      <div className="p-4">
        <Button
          variant="outline"
          className="w-full justify-start mb-2"
          onClick={() => navigate("/dashboard")}
        >
          Exit Admin
        </Button>
        <Button
          variant="outline"
          className="w-full justify-start"
          onClick={logout}
        >
          Logout
        </Button>
      </div>
    </div>
  );

  return useObserver(() => (
    <>
      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="md:hidden">
            <Menu className="h-6 w-6" />
          </Button>
        </SheetTrigger>
        <SheetContent side="left" className="w-64 p-0">
          <NavContent />
        </SheetContent>
      </Sheet>

      <div className="hidden md:flex h-screen w-64 flex-col border-r bg-background">
        <NavContent />
      </div>
    </>
  ));
}
