import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AppLayout } from "../shared/layout";
import { getEmailAccount, updateEmailAccount } from "../../../data";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
export function MailAccountUpdateScreen() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const pageSettings = {
    title: t("emailAccountList.emailAccountDetail"),
    actions: [
      {
        name: t("common.goToList"),
        color: "blue",
        icon: "add-icon",
        onClick: () => {
          navigate("/email-accounts");
        },
      },
    ],
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { data, isLoading, error } = useQuery({
    queryKey: ["emailAccount", id],
    queryFn: async () => await getEmailAccount(id),
    retry: 0,
  });

  const mutation = useMutation({
    mutationKey: ["updateEmailAccount", id],
    mutationFn: (data) => {
      return updateEmailAccount(data.id, data.data);
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ["emailAccount", id] });
    },
  });

  const onSubmit = async (data) => {
    const { name, type, ...accountDetails } = data;
    mutation.mutate({
      id: id,
      data: { name, type, ...accountDetails },
    });
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return (
      <AppLayout>
        <div>{t("common.loading")}</div>
      </AppLayout>
    );
  }

  return (
    <AppLayout
      title={pageSettings.title + " - " + id}
      actions={pageSettings.actions}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="name"
          >
            {t("emailAccountList.name")}
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="name"
            type="text"
            placeholder={t("emailAccountList.name")}
            {...register("name", {
              required: t("emailAccountList.nameRequired"),
            })}
            defaultValue={data.data.name}
          />
          {errors.name && <p>{errors.name.message}</p>}
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="type"
          >
            {t("emailAccountList.type")}
          </label>
          <select
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="type"
            {...register("type", {
              required: t("emailAccountList.typeRequired"),
            })}
            defaultValue={data.data.type}
          >
            <option value="resend">Resend</option>
            <option value="gmail">Gmail</option>
            <option value="smtp">SMTP</option>
          </select>
          {errors.type && <p>{errors.type.message}</p>}
        </div>

        {data.data.type === "resend" && (
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="apiKey"
            >
              {t("emailAccountList.apiKey")}
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="apiKey"
              type="text"
              placeholder={t("emailAccountList.apiKey")}
              {...register("apiKey", {
                required: t("emailAccountList.apiKeyRequired"),
              })}
              defaultValue={data.data.apiKey}
            />
            {errors.apiKey && <p>{errors.apiKey.message}</p>}
          </div>
        )}

        {data.data.type === "gmail" && (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="clientId"
              >
                {t("emailAccountList.clientId")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="clientId"
                type="text"
                placeholder={t("emailAccountList.clientId")}
                {...register("clientId", {
                  required: t("emailAccountList.clientIdRequired"),
                })}
                defaultValue={data.data.clientId}
              />
              {errors.clientId && <p>{errors.clientId.message}</p>}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="clientSecret"
              >
                {t("emailAccountList.clientSecret")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="clientSecret"
                type="text"
                placeholder="Client Secret"
                {...register("clientSecret", {
                  required: t("emailAccountList.clientSecretRequired"),
                })}
                defaultValue={data.data.clientSecret}
              />
              {errors.clientSecret && <p>{errors.clientSecret.message}</p>}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="refreshToken"
              >
                {t("emailAccountList.refreshToken")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="refreshToken"
                type="text"
                placeholder="Refresh Token"
                {...register("refreshToken", {
                  required: t("emailAccountList.refreshTokenRequired"),
                })}
                defaultValue={data.data.refreshToken}
              />
              {errors.refreshToken && <p>{errors.refreshToken.message}</p>}
            </div>
          </>
        )}

        {data.data.type === "smtp" && (
          <>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                {t("emailAccountList.email")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                placeholder={t("emailAccountList.email")}
                {...register("email", {
                  required: t("emailAccountList.emailRequired"),
                })}
                defaultValue={data.data.email}
              />
              {errors.email && <p>{errors.email.message}</p>}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                {t("emailAccountList.password")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="password"
                placeholder={t("emailAccountList.password")}
                {...register("password", {
                  required: t("emailAccountList.passwordRequired"),
                })}
                defaultValue={data.data.password}
              />
              {errors.password && <p>{errors.password.message}</p>}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="host"
              >
                {t("emailAccountList.host")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="host"
                type="text"
                placeholder={t("emailAccountList.host")}
                {...register("host", {
                  required: t("emailAccountList.hostRequired"),
                })}
                defaultValue={data.data.host}
              />
              {errors.host && <p>{errors.host.message}</p>}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="port"
              >
                {t("emailAccountList.port")}
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="port"
                type="number"
                placeholder={t("emailAccountList.port")}
                {...register("port", {
                  required: t("emailAccountList.portRequired"),
                })}
                defaultValue={data.data.port}
              />
              {errors.port && <p>{errors.port.message}</p>}
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="ssl"
              >
                {t("emailAccountList.ssl")}
              </label>
              <input
                type="checkbox"
                className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="ssl"
                {...register("ssl")}
                defaultChecked={data.data.ssl}
              />
              {errors.ssl && <p>{errors.ssl.message}</p>}
            </div>
          </>
        )}

        <div className="flex items-center justify-between">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            {t("common.update")}
          </button>
        </div>
      </form>
    </AppLayout>
  );
}
