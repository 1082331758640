import api from "../api";

const submitEmail = (email) => {
    return api.getApiWithoutToken().post(api.getBaseUrl() + "waiting-list", { email });
  };

const getWaitingList = () => {
    return api.getApi().get(api.getBaseUrl() + "waiting-list");
};

export { submitEmail, getWaitingList };