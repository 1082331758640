import React from "react";

import { LandingPageLayout } from "../shared/layout";

export function AboutPageScreen() {
  return (
    <LandingPageLayout>
      <section className="my-10 body-font">
        <div className=" lg:pl-24  flex flex-col "></div>
      </section>
    </LandingPageLayout>
  );
}
