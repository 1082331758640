import React, { useState, useEffect, useRef } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { AppLayout } from "../shared/layout";
import {
  getAppEmailTemplates,
  getDocument,
  createEmailTransaction,
  updateDocument,
} from "../../../data";
import { AdvancedEmailEditor } from "../../../components/mail/advance-email-editor";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Badge } from "@/components/ui/badge";
import { getEmailAccounts } from "../../../data";
import { DragAndDropTextEditor } from "@/components";
import {
  ChartNoAxesColumn,
  CheckCircle,
  ChevronLeft,
  ChevronRight,
  Clock,
  FileSpreadsheet,
  Mail,
  Users,
} from "lucide-react";
import { DocumentSteps } from "../../../components";
import { GripVertical, Tag } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { isValidEmail } from "../../../lib/utils";
import { sendMail } from "@/data/requests/document-requests";
import { useTranslation } from "react-i18next";

export function DocumentDetailScreen() {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [currentStep, setCurrentStep] = useState(0);
  const { toast } = useToast();
  const { t } = useTranslation();
  const handleStepClick = (stepIndex) => {
    setCurrentStep(stepIndex);
  };

  const formRef = useRef(null);
  const { data, isLoading, error } = useQuery({
    queryKey: ["document", id],
    queryFn: () => getDocument(id),
  });

  const updateDocumentMutation = useMutation({
    mutationFn: (documentData) => updateDocument(id, documentData),
    onSuccess: () => {
      queryClient.invalidateQueries(["document", id]);
    },
  });

  const triggerSaveDocument = () => {
    formRef.current.click();
  };

  const { control, handleSubmit, watch, setValue, register } = useForm({
    defaultValues: {
      name: "",
      rowCount: "",
      headerCount: "",
      emailAccount: "",
      emailSubject: "",
      emailTo: "",
      content: "",
    },
  });

  const emailSubject = watch("emailSubject");
  const emailTo = watch("emailTo");
  const content = watch("content");

  const [previewIndex, setPreviewIndex] = useState(0);
  const [previews, setPreviews] = useState([]);

  const { data: templates, isLoading: isTemplatesLoading } = useQuery({
    queryKey: ["templates"],
    queryFn: getAppEmailTemplates,
  });

  const replaceTemplateVariables = (template, data) => {
    return template.replace(/\$\{([^}]+)\}/g, (match, key) => {
      const foundValue = data.find((item) => item.header.name === key);
      return foundValue ? foundValue.value : match;
    });
  };

  const generatePreviews = () => {
    if (data && data.rows && data.rows.length > 0) {
      const previewData = data.rows.slice(0, 3).map((row) => {
        const rowData = row.rowValues;
        return {
          subject: replaceTemplateVariables(emailSubject, rowData),
          to: replaceTemplateVariables(emailTo, rowData),
          content: replaceTemplateVariables(content, rowData),
        };
      });
      setPreviews(previewData);
    }
  };

  useEffect(() => {
    generatePreviews();
  }, [emailSubject, emailTo, content, data]);

  const validateEmailHeader = (headerName) => {
    console.log("validateEmailHeader", headerName);
    var validCount = 0;
    var invalidCount = 0;
    if (data && data.rows) {
      data.rows[0].rowValues.forEach((row) => {
        const email = row.header.name == headerName ? row.value : "";
        if (email) {
          console.log("email", email);
          if (isValidEmail(email)) {
            validCount++;
          } else {
            invalidCount++;
          }
        }
      });
    }
    console.log("validCount", validCount);
    console.log("invalidCount", invalidCount);
    try {
      if (invalidCount > 0) {
        toast({
          variant: "destructive",
          title: t("documentDetails.emailValidation"),
          description: t("documentDetails.invalidEmailAddresses"),
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handlePrevPreview = () => {
    setPreviewIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : previews.length - 1
    );
  };

  const handleNextPreview = () => {
    setPreviewIndex((prevIndex) =>
      prevIndex < previews.length - 1 ? prevIndex + 1 : 0
    );
  };

  const onSubmit = async (formData) => {
    updateDocumentMutation.mutate(formData);
    setCurrentStep(4);
  };

  const createEmailTransactionMutation = useMutation({
    mutationKey: ["createEmailTransaction", id],
    mutationFn: () => createEmailTransaction(id),
    onSuccess: () => {
      toast({
        title: t("documentDetails.emailTransactionCreated"),
        description: t("documentDetails.emailTransactionCreatedSuccess"),
      });
    },
  });

  const handleCreateEmailTransaction = () => {
    console.log("createEmailTransaction", id);
    createEmailTransactionMutation.mutate();
  };

  const { data: emailAccounts, isLoading: isEmailAccountsLoading } = useQuery({
    queryKey: ["emailAccounts"],
    queryFn: getEmailAccounts,
  });

  const sendMailMutation = useMutation({
    mutationKey: ["sendMail", id],
    mutationFn: () => sendMail(id),
    onSuccess: () => {
      toast({
        title: t("documentDetails.emailSent"),
        description: t("documentDetails.emailSentSuccess"),
      });
    },
  });

  const handleSendMail = () => {
    sendMailMutation.mutate();
  };

  if (isLoading)
    return (
      <AppLayout>
        <div>{t("documentDetails.loading")}</div>
      </AppLayout>
    );
  if (error)
    return (
      <AppLayout>
        <div>
          {t("common.error")}: {error.message}
        </div>
      </AppLayout>
    );

  return (
    <AppLayout
      title={`${data?.name || t("documentDetails.document")} - ${id}`}
      actions={[
        {
          name: t("documentDetails.saveDocument"),
          onClick: triggerSaveDocument,
        },
        {
          name: t("documentDetails.mailTransactions"),
          onClick: () => navigate(`/documents/${id}/email-transactions`),
        },
        {
          name: t("documentDetails.createEmailTransaction"),
          onClick: handleCreateEmailTransaction,
        },
        {
          name: t("documentDetails.sendMail"),
          onClick: handleSendMail,
        },
      ]}
    >
      <DocumentSteps currentStep={currentStep} onStepClick={handleStepClick} />
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        {currentStep === 0 && (
          <Card className="mb-6">
            <CardHeader>
              <CardTitle>
                {t("documentDetails.emailAccountSelection")}
              </CardTitle>
            </CardHeader>
            <CardContent>
              <Controller
                name="emailAccountId"
                control={control}
                render={({ field }) => (
                  <div className="relative">
                    <select
                      className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setValue("emailAccountId", e.target.value);
                        setCurrentStep(1);
                      }}
                      value={field.value}
                      disabled={isEmailAccountsLoading}
                    >
                      <option value="" disabled selected>
                        {t("documentDetails.selectEmailAccount")}
                      </option>
                      {isEmailAccountsLoading ? (
                        <option value="loading">{t("common.loading")}</option>
                      ) : emailAccounts?.length ? (
                        emailAccounts.map((account) => (
                          <option key={account.id} value={account.id}>
                            {account.name}
                          </option>
                        ))
                      ) : (
                        <option value="no-accounts">
                          {t("documentDetails.noEmailAccounts")}
                        </option>
                      )}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                )}
              />
              {isEmailAccountsLoading && (
                <p className="mt-2 text-sm text-gray-500">
                  {t("common.loading")}
                </p>
              )}
              {!isEmailAccountsLoading && emailAccounts?.length === 0 && (
                <p className="mt-2 text-sm text-red-500">
                  {t("documentDetails.noEmailAccountsWarning")}
                </p>
              )}
            </CardContent>
          </Card>
        )}

        {currentStep === 1 && (
          <Card className="mb-6">
            <CardHeader>
              <CardTitle>{t("documentDetails.emailSubjectAndTo")}</CardTitle>
            </CardHeader>
            <CardContent className="grid grid-cols-2 gap-4">
              <Controller
                name="emailSubject"
                control={control}
                render={({ field }) => (
                  <DragAndDropTextEditor
                    title={t("documentDetails.emailSubject")}
                    content={field.value}
                    onSetContent={(newContent) => {
                      field.onChange(newContent);
                      setValue("subject", newContent);
                    }}
                    isSingleItem={false}
                  />
                )}
              />
              <Controller
                name="emailTo"
                control={control}
                render={({ field }) => (
                  <DragAndDropTextEditor
                    title={t("documentDetails.emailToDropHeader")}
                    content={field.value}
                    onSetContent={(newContent) => {
                      field.onChange(newContent);
                      setValue("to", newContent);
                    }}
                    isSingleItem={true}
                    onDropFinish={(headerName) => {
                      if (headerName) {
                        validateEmailHeader(headerName);
                      }
                    }}
                  />
                )}
              />
              <div className="col-span-2 mb-4">
                <Label className="text-sm font-medium mb-2 block">
                  {t("documentDetails.headersForDrag")}
                </Label>
                <div className="flex flex-wrap gap-2 mt-2">
                  {data?.headers?.map((header, index) => (
                    <Badge
                      key={index}
                      variant="secondary"
                      className="pl-2 pr-3 py-1 text-sm font-medium bg-gray-100 hover:bg-gray-200 transition-colors duration-200 cursor-move flex items-center"
                      draggable
                      onDragStart={(e) =>
                        e.dataTransfer.setData("text", header.name)
                      }
                    >
                      <GripVertical className="h-4 w-4 mr-1 text-gray-500" />
                      <Tag className="h-4 w-4 mr-1 text-blue-500" />
                      {header.name}
                    </Badge>
                  )) || t("documentDetails.noHeadersAvailable")}
                </div>
              </div>
              <Button className="mt-4" onClick={() => setCurrentStep(2)}>
                {t("common.next")}
              </Button>
            </CardContent>
          </Card>
        )}

        {currentStep === 2 && (
          <Card className="mb-6">
            <CardHeader>
              <CardTitle>
                {t("documentDetails.emailTemplateCreation")}
              </CardTitle>
            </CardHeader>
            <CardContent>
              <Controller
                name="emailSubject"
                control={control}
                render={({ field }) => (
                  <DragAndDropTextEditor
                    title={t("documentDetails.emailSubject")}
                    content={field.value}
                    onSetContent={(newContent) => {
                      field.onChange(newContent);
                      setValue("subject", newContent);
                    }}
                  />
                )}
              />
              <Controller
                name="emailTo"
                control={control}
                render={({ field }) => (
                  <DragAndDropTextEditor
                    title={t("documentDetails.emailTo")}
                    content={field.value}
                    onSetContent={(newContent) => {
                      field.onChange(newContent);
                      setValue("to", newContent);
                    }}
                  />
                )}
              />
              <Controller
                name="content"
                control={control}
                render={({ field }) => (
                  <AdvancedEmailEditor
                    title={t("documentDetails.content")}
                    minHeight="500px"
                    content={field.value}
                    templates={templates}
                    onSetContent={(newContent) => {
                      field.onChange(newContent);
                      setValue("mailContent", newContent);
                    }}
                    headers={data?.headers || []}
                  />
                )}
              />
              <Button className="mt-4" onClick={() => setCurrentStep(3)}>
                {t("common.next")}
              </Button>
            </CardContent>
          </Card>
        )}

        {currentStep === 3 && (
          <Card className="mb-6">
            <CardHeader>
              <CardTitle>{t("documentDetails.emailPreview")}</CardTitle>
            </CardHeader>
            <CardContent>
              {previews.length > 0 ? (
                <>
                  <div className="mb-4">
                    <Label>{t("documentDetails.subject")}</Label>
                    <div className="mt-1 p-2 bg-gray-100 rounded">
                      {previews[previewIndex].subject ||
                        t("documentDetails.noSubject")}
                    </div>
                  </div>
                  <div className="mb-4">
                    <Label>{t("documentDetails.to")}</Label>
                    <div className="mt-1 p-2 bg-gray-100 rounded">
                      {previews[previewIndex].to ||
                        t("documentDetails.noRecipient")}
                    </div>
                  </div>
                  <div>
                    <Label>{t("documentDetails.content")}</Label>
                    <div
                      className="mt-1 p-2 bg-gray-100 rounded min-h-[200px]"
                      dangerouslySetInnerHTML={{
                        __html:
                          previews[previewIndex].content ||
                          t("documentDetails.noContent"),
                      }}
                    />
                  </div>
                  <div className="flex justify-between mt-4">
                    <Button
                      onClick={handlePrevPreview}
                      disabled={previews.length <= 1}
                    >
                      <ChevronLeft className="mr-2 h-4 w-4" />{" "}
                      {t("common.previous")}
                    </Button>
                    <Button
                      onClick={handleNextPreview}
                      disabled={previews.length <= 1}
                    >
                      {t("common.next")}{" "}
                      <ChevronRight className="ml-2 h-4 w-4" />
                    </Button>
                  </div>
                  <div className="text-center mt-2">
                    {t("documentDetails.previewCount", {
                      current: previewIndex + 1,
                      total: previews.length,
                    })}
                  </div>
                </>
              ) : (
                <div>{t("documentDetails.noPreviewAvailable")}</div>
              )}
            </CardContent>
          </Card>
        )}
        {currentStep === 4 && (
          <Card className="mb-6">
            <CardHeader>
              <CardTitle>{t("documentDetails.finalCheck")}</CardTitle>
            </CardHeader>
            <CardContent>
              <div>{t("documentDetails.finalCheckDescription")}</div>
              <Button type="submit">{t("documentDetails.saveDocument")}</Button>
            </CardContent>
          </Card>
        )}
      </form>
    </AppLayout>
  );
}
