import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { CheckCircle, Mail, FileSpreadsheet, Zap } from "lucide-react";
import { LandingPageLayout } from "../shared/layout";

export function LandingPageScreen() {
  const { t } = useTranslation();

  const features = [
    t("features.excelImport"),
    t("features.bulkEmailSending"),
    t("features.customTemplates"),
    t("features.emailTracking"),
    t("features.dataManagement"),
    t("features.secureAuthentication"),
  ];

  return (
    <LandingPageLayout>
      <div className="flex flex-col min-h-screen">
        <main className="flex-grow bg-gradient-to-b from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800">
          <div className="container mx-auto px-4 py-16">
            <div className="text-center mb-16">
              <Badge variant="secondary" className="mb-4">
                {t("newVersion")}
              </Badge>
              <h1 className="text-5xl font-extrabold tracking-tight mb-4">
                {t("sendExcelTitle")}
              </h1>
              <p className="text-xl text-gray-600 dark:text-gray-300 mb-8 max-w-2xl mx-auto">
                {t("sendExcelDescription")}
              </p>
              <Button size="lg" asChild className="mr-4">
                <Link to="/register">{t("getStarted")}</Link>
              </Button>
            </div>

            <div className="grid md:grid-cols-3 gap-8 mb-16">
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center">
                    <FileSpreadsheet className="mr-2 h-5 w-5 text-primary" />
                    {t("excelIntegration")}
                  </CardTitle>
                </CardHeader>
                <CardContent>{t("excelIntegrationDesc")}</CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center">
                    <Mail className="mr-2 h-5 w-5 text-primary" />
                    {t("bulkEmailing")}
                  </CardTitle>
                </CardHeader>
                <CardContent>{t("bulkEmailingDesc")}</CardContent>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle className="flex items-center">
                    <Zap className="mr-2 h-5 w-5 text-primary" />
                    {t("efficientWorkflow")}
                  </CardTitle>
                </CardHeader>
                <CardContent>{t("efficientWorkflowDesc")}</CardContent>
              </Card>
            </div>

            <div className="text-center mb-16">
              <h2 className="text-3xl font-bold mb-8">
                {t("featuredFeatures")}
              </h2>
              <ul className="grid md:grid-cols-2 gap-4 max-w-2xl mx-auto text-left">
                {features.map((feature, index) => (
                  <li key={index} className="flex items-center">
                    <CheckCircle className="text-green-500 mr-2" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="bg-white dark:bg-gray-800 p-8 rounded-lg shadow-lg text-center">
              <h2 className="text-3xl font-bold mb-4">
                {t("startWithExcelify")}
              </h2>
              <p className="text-xl text-gray-600 dark:text-gray-300 mb-8">
                {t("startWithExcelifyDesc")}
              </p>
              <Button size="lg" asChild>
                <Link to="/register">{t("createFreeAccount")}</Link>
              </Button>
            </div>
          </div>
        </main>
      </div>
    </LandingPageLayout>
  );
}
