import { Link } from "react-router-dom";
import AuthStore from "../../stores/AuthStore";
import { observer } from "mobx-react";
import { Button } from "../ui/button";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "../ui/navigation-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { useTranslation } from "react-i18next";

export const NavbarLandingPage = observer(() => {
  const { i18n, t } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  return (
    <nav className="border-b-2">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <Link
            className="flex title-font font-medium items-center text-gray-900"
            to="/"
          >
            {t("appName")}
          </Link>

          <NavigationMenu>
            <NavigationMenuList>
              {/* You can add more menu items here */}
            </NavigationMenuList>
          </NavigationMenu>

          <div className="flex items-center space-x-4">
            <Select onValueChange={changeLanguage} defaultValue={i18n.language}>
              <SelectTrigger className="w-[60px]">
                <SelectValue placeholder={t("language")} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="en">{t("english")}</SelectItem>
                <SelectItem value="tr">{t("turkish")}</SelectItem>
              </SelectContent>
            </Select>
            {AuthStore.isLogin || AuthStore.isHaveTokenLogin() ? (
              <Button asChild variant="default">
                <Link to="/dashboard">{t("goToDashboard")}</Link>
              </Button>
            ) : (
              <>
                <Button asChild variant="ghost">
                  <Link to="/login">{t("login")}</Link>
                </Button>
                <Button asChild variant="default">
                  <Link to="/register">{t("register")}</Link>
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
});
